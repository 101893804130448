import Link from "next/link";
import React from "react";
import { useStoreContext } from "../../contexts/StoreDataContext";
import { mixpanel } from "../../services/helper";

export default function RegularFooter() {
  const storeData = useStoreContext();

  const navigateToHome = () => {
    const url = `https://dm2buy.app.link/apps?utm_source=footer&utm_campaign=${storeData.subdomain}`;
    mixpanel.track("footer_cta", {
      referrer: storeData.subdomain,
    });
    window.open(url, "_blank").focus();
  };

  const legalInfo = storeData?.legalInfo;
  const terms = legalInfo?.terms;
  const privacy = legalInfo?.privacy;
  const about = legalInfo?.about;
  const policy = legalInfo?.policy;
  const care = legalInfo?.care;
  const size = legalInfo?.size;

  return (
    <footer className="w-full mt-20 flex flex-col px-5 pb-8">
      <div
        className="flex flex-col items-center text-2xl font-bold text-[#1c3142] tracking-wide cursor-pointer"
        onClick={navigateToHome}
      >
        dm2buy
      </div>
      <div className="mt-10 flex flex-wrap justify-evenly gap-4 text-sm text-gray-700">
        {policy && <Link href="/policy">Store Policy</Link>}
        {care && <Link href="/care">Product Care</Link>}
        {size && <Link href="/size">Size</Link>}
        {terms && <Link href="/terms">Terms</Link>}
        <Link href="/returns">Shipping & Return</Link>
        {privacy && <Link href="/privacy">Privacy</Link>}
        <Link href="/contact">Contact Us</Link>
        {about && <Link href="/about">About</Link>}
      </div>
    </footer>
  );
}
