import Image from "next/image";
import React from "react";
import EllipsisText from "react-ellipsis-text";
import { useScrollDirection } from "react-use-scroll-direction";

const NavBar = (props) => {
  const [direction, setDirection] = React.useState(String);
  const { isScrollingUp, isScrollingDown } = useScrollDirection();

  React.useEffect(() => {
    isScrollingDown && setDirection("down");
    isScrollingUp && setDirection("up");
  }, [isScrollingDown, isScrollingUp]);

  const checkoutShouldHide = () => {
    let shouldHide = false;
    if (typeof window != "undefined") {
      if (window.scrollY > 120) {
        shouldHide = true;
      }
    }
    return shouldHide;
  };

  const makeBGWhite = () => {
    let shouldHide = false;
    if (typeof window != "undefined") {
      if (window.scrollY > 30) {
        shouldHide = true;
      }
    }
    return shouldHide;
  };

  return (
    <header
      className={`flex flex-row justify-between items-center h-[72px] w-full p-[0.5rem_22px] z-[99] fixed top-0 left-1/2 max-w-[var(--max-width)] bg-transparent -translate-x-1/2 opacity-100 transition-all duration-300 ease-in-out
        ${direction === "up" ? "bg-white" : ""} 
        ${direction === "down" && checkoutShouldHide() ? "-top-[60px]" : ""} 
        ${direction === "down" && makeBGWhite() ? "!bg-white" : ""}`}
    >
      <div className="relative flex justify-center items-center z-10 w-full">
        <h2
          className="inline-block text-lg font-bold font-lato text-black text-center"
          onClick={() => (window.location.href = window.location.origin)}
        >
          {props.store?.logo ? (
            <Image
              unoptimized="true"
              src={props.store.logo}
              alt={`${props.store.storeName} Logo`}
              width={50}
              height={50}
              style={{ height: "auto" }} // This ensures the aspect ratio is maintained
              layout="fixed"
            />
          ) : (
            <EllipsisText text={props.storeName} length={25} tail="..." />
          )}
        </h2>
        <span className="z-10 absolute right-0">
          {props.cartActive && !props.hideInAdvance ? (
            <Image
              onClick={() => props.handleShowCart(true)}
              src="/cart-icon.svg"
              layout="fixed"
              width="21"
              height="21"
            />
          ) : (
            ""
          )}
        </span>
      </div>
    </header>
  );
};

export default NavBar;
