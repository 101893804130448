import Image from "next/image";
import Link from "next/link";
import React from "react";
import { useStoreContext } from "../../contexts/StoreDataContext";
import { mixpanel } from "../../services/helper";
import RegularFooter from "./RegularFooter";

export default function FeaturedFooter() {
  const storeData = useStoreContext();
  const navigateToHome = () => {
    const url = `https://dm2buy.app.link/apps?utm_source=footer&utm_campaign=${storeData.subdomain}`;
    mixpanel.track("footer_cta", {
      referrer: storeData.subdomain,
    });
    window.open(url, "_blank").focus();
  };

  const navigateToInstagram = () => {
    const url = `https://www.instagram.com/${storeData.instagramHandle}`;
    if (typeof window !== "undefined") {
      const newWindow = window.open(url, "_blank");
      if (newWindow) {
        newWindow.focus();
      }
    }
  };

  const isNoLegaInfoAvailable = !storeData?.legalInfo;

  if (!isNoLegaInfoAvailable) {
    const legalInfo = storeData?.legalInfo;
    const terms = legalInfo?.terms;
    const contacts = legalInfo?.contacts;
    const returns = legalInfo?.returns;
    const about = legalInfo?.about;
    const policy = legalInfo?.policy;
    const care = legalInfo?.care;
    const size = legalInfo?.size;
    // const privacy = legalInfo?.privacy;
    // const isNoLegalDataAvailable =
    //   !terms && !contacts && !returns && !privacy && !about && !policy && !care && !size;

    return (
      <footer className="w-full mt-20 flex flex-col px-5 pb-8">
        {/* Quick Links */}
        <div className="flex flex-col">
          <div className="font-bold text-base mt-4 mb-1">Quick Links</div>
          {policy && (
            <Link href="/policy">
              <a className="leading-[2px] text-sm mt-[1.2rem]">Privacy Policy</a>
            </Link>
          )}
          {care && (
            <Link href="/care" className="leading-[2px]">
              <a className="leading-[2px] text-sm mt-[1.2rem]">Product Care</a>
            </Link>
          )}
          {size && (
            <Link href="/size" className="leading-[2px]">
              <a className="leading-[2px] text-sm mt-[1.2rem]">Size</a>
            </Link>
          )}
          {terms && (
            <Link href="/terms" className="leading-[2px]">
              <a className="leading-[2px] text-sm mt-[1.2rem]">Terms</a>
            </Link>
          )}
          {returns && (
            <Link href="/returns" className="leading-[2px]">
              <a className="leading-[2px] text-sm mt-[1.2rem]">Shipping & Return</a>
            </Link>
          )}
          {contacts && (
            <Link href="/contact" className="leading-[2px]">
              <a className="leading-[2px] text-sm mt-[1.2rem]">Contact Us</a>
            </Link>
          )}
          {about && (
            <Link href="/about" className="leading-[2px]">
              <a className="leading-[2px] mt-4 mb-[10px]">About</a>
            </Link>
          )}
        </div>

        {/* About Us */}
        <div>
          <div className="font-bold text-base  mt-4 mb-[10px]">About Us</div>
          <div className="text-gray-500 leading-6 text-sm">{storeData?.storeBio}</div>
        </div>

        {/* Social */}
        <div>
          <div className="font-bold text-base  mt-4 mb-[10px]">Social</div>
          <div>
            <Image
              onClick={navigateToInstagram}
              src="https://dm2buy-aqbqh9cwb5cwb9he.z02.azurefd.net/dm2buy/dm2buy_instagram.png"
              alt=""
              width={20}
              height={20}
              layout="fixed"
            />
          </div>
        </div>

        {/* Secure Payments */}
        <div>
          <div className="font-bold text-base  mt-4">Secure Payments via Cashfree</div>
          <div className="flex justify-evenly">
            <Image
              src="https://dm2buy-aqbqh9cwb5cwb9he.z02.azurefd.net/dm2buy/dm2buy_asset_allpg.svg"
              alt=""
              layout="fixed"
              width={500}
              height={60}
            />
          </div>
        </div>

        <div className="text-center text-gray-500 text-sm mb-3">
          <div onClick={navigateToHome}>
            <p>
              ©2025 by {storeData.storeName}. Made with love on
              <a className="underline text-blue-500 cursor-pointer">&nbsp;dm2buy</a> ✌🏼
            </p>
          </div>
        </div>
      </footer>
    );
  }

  return <RegularFooter />;
}
