import React from "react";
import { getPrice } from "../../services/frontend/pricing.service";
import { getStockAvailability, mixpanel } from "../../services/helper";
import LoaderComponent from "../Loader";
import BagItem from "./BagItem";

export default class Basket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      refresh: false,
      pageHeight: 0,
      newBottom: false,
      isStockAvailable: false,
    };
  }

  componentDidMount() {
    this.setState({ pageHeight: window.innerHeight });
    window.addEventListener("resize", this.handleWindowResize, true);
    this.handleWindowResize();
    getStockAvailability(this.props.cartData.products).then((isStockAvailable) => {
      this.setState({ isStockAvailable });
    });
  }

  handleWindowResize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  removeProductFromCart = (productId) => () => {
    const cartData = this.props.cartData;
    const filteredProducts = cartData.products.filter((product) => product.id !== productId);
    if (filteredProducts.length === 0) {
      this.props.handleShowCart(false);
      if (!this.props.fromProductPage) this.props.setRefresh();
      if (this.props.setHideInAdvance) {
        this.props.setHideInAdvance(true);
      }
    }
    setTimeout(() => {
      cartData.products = filteredProducts;
      this.props.StorageManager.putJson(this.props.CART_KEY, cartData);
      if (this.props.fromProductPage) {
        this.props.setCart(cartData, true);
      } else {
        this.props.setCart(cartData);
      }
      this.setState({ refresh: !this.state.refresh });
      if (this.props.updateAddedToCart) {
        this.props.updateAddedToCart(productId, false);
      }
    }, 300);
  };

  updateProductCount = (productId, variant, count) => {
    const cartData = this.props.cartData;
    const productIndex = cartData.products.findIndex(
      (product) => product.id === productId && product.size === variant,
    );
    if (productIndex < 0) return;

    cartData.products[productIndex].quantity = count;
    this.props.StorageManager.putJson(this.props.CART_KEY, cartData);
    this.props.setCart(cartData);
    this.setState({ refresh: !this.state.refresh });
  };

  render() {
    const { productTotalPrice, shippingFee } = getPrice(this.props.cartData);
    const price = productTotalPrice - shippingFee;

    return (
      <aside
        className={`fixed top-0 opacity-0 overflow-hidden h-screen w-full max-w-[var(--max-width)] bg-white transition-all duration-500 ease-in-out z-[100] m-0 auto ${
          this.props.isBasketOpen ? "opacity-100 left-0" : "left-full"
        }`}
      >
        {this.state.loading && <LoaderComponent />}
        <div className="flex flex-col items-center h-screen p-6 overflow-y-auto">
          <h2 className="font-lato text-xl w-full font-bold tracking-wider m-0 relative">
            <span className="mr-3">🛍️</span> Your Bag
            <button
              className="inline-block bg-transparent border-none w-[22px] absolute top-[5px] right-0 z-50"
              onClick={() => this.props.handleShowCart(!this.props.isBasketOpen)}
            >
              <div
                className={`w-full h-[15px] relative transform rotate-0 transition duration-500 ease-in-out cursor-pointer ${this.props.isBasketOpen ? "open" : ""}`}
              >
                <span
                  className={`block absolute h-[2px] w-full bg-black rounded-lg opacity-100 left-0 transform transition duration-250 ease-in-out top-[7px] ${this.props.isBasketOpen ? "rotate-45" : "rotate-0"}`}
                ></span>
                <span
                  className={`block absolute h-[2px] w-full bg-black rounded-lg opacity-100 left-0 transform transition duration-250 ease-in-out top-[7px] ${this.props.isBasketOpen ? "-rotate-45" : "rotate-0"}`}
                ></span>
              </div>
            </button>
          </h2>
          <div className="my-10 w-full mb-[50px]">
            {React.Children.toArray(
              this.props.cartData.products.map((product) => (
                <BagItem
                  item={product}
                  removeProductFromCart={this.removeProductFromCart}
                  updateProductCount={this.updateProductCount}
                />
              )),
            )}
          </div>
          <div className="h-[120px] w-full"></div>
        </div>
        {this.props.isBasketOpen ? (
          <div className="fixed left-1/2 w-full bottom-0 transform -translate-x-1/2 bg-white max-w-[var(--max-width)] px-3 py-4 shadow-[0_-1px_4.5px_0_#ccc]">
            <button
              className="bg-none border-none outline-none w-full py-4 rounded-2xl bg-black text-white text-sm font-roboto font-normal tracking-wider uppercase"
              style={{ opacity: this.props.store?.checkoutDisabled ? 0.2 : 1 }}
              disabled={this.props.store?.checkoutDisabled}
              onClick={async () => {
                console.log("mixpanel checkout click" + mixpanel);
                mixpanel.track("clicked_checkout", { cart_data: this.props.cartData.products });
                window.location.href = `/cart/checkout`;
              }}
            >
              Checkout — ₹{price}
            </button>
            {/* <button
              className="bg-none border-none outline-none w-full py-4 rounded-2xl bg-white text-black text-sm font-roboto font-bold tracking-wider uppercase"
              onClick={() => this.props.handleShowCart(!this.props.isBasketOpen)}
            >
              Continue Shopping
            </button> */}
          </div>
        ) : (
          ""
        )}
      </aside>
    );
  }
}
