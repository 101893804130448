import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const LoaderComponent = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-white z-[95]">
    <Loader type="Oval" color="#ccc" height={40} width={40} timeout={3000} />
  </div>
);

export default LoaderComponent;
